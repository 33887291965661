<template>
    <div class="vueTabs" :id="id">
        <h2 class="tabsList-title">{{tabTitle}}</h2>
        <div class="tabsContainer">
            <div class="stick-top" style="height: 0px;"></div>
            <div class="tabs tabsWrapper">
                <div class="navbar navbarContainer scrollNav">
                    <div class="navBarWrap">
                        <div class="gradient-left" onclick="displayTab(this)"><i class="fas fa-chevron-left scrollArrow"></i></div>
                        <a class="tab" v-on:click="activetab=i" v-bind:class="[ activetab === i ? 'active' : '' ]"
                            onclick="displayTab(this)"
                            v-for="(child, i) in tabLabel.slice(0, 8)"
                            :key="i" 
                            :name="child.replace(new RegExp(' ', 'g'), '').toLowerCase().trim()"
                            :tabindex="0"
                            :aria-selected="[ activetab === i ? 'true' : 'false' ]"
                            :aria-controls="child.replace(new RegExp(' ', 'g'), '').toLowerCase().trim() + '-tab-' + id"
                            role="tab"
                        >
                            <p>{{child}}</p>
                        </a>
                        <div class="gradient-right" onclick="displayTab(this)"><i class="fas fa-chevron-right scrollArrow"></i></div>
                    </div>
                </div>
                <div class="content">
                    <div v-html="child" class="tabContent" 
                        v-for="(child, j) in tabContent.slice(0, 8)"       
                        :key="j" 
                        :aria-hidden="[ activetab === j ? 'false' : 'true' ]"
                        :aria-labelledby="tabLabel[j].replace(new RegExp(' ', 'g'), '').toLowerCase().trim()"
                        v-if="activetab === j"
                        role="tabpanel"
                        :id="tabLabel[j].replace(new RegExp(' ', 'g'), '').toLowerCase().trim() + '-tab-' + id"
                        >
                        <div>
                        {{child}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="tabEnd"></div>
    </div>
</template>

<script>

    import axios from 'AxiosCDN'

    export default {
        name: 'Tabs',
        props: {
            id: String
        },
        data() {
            return {
                activetab: 0,
                tabTitle: '',
                tabLabel: [],
                tabContent: []
            }
        },
        created() {
            this.getServerPath()
            this.getTabContent()
        },
        mounted() {
        },
        ready() {
        },
        computed: {
        },
        components: {
        },
        destroyed () {
        },
        methods: {
            getServerPath() {
                const url = window.location.href.toLowerCase(),
                    locations = ['.gov', 'dsspreview', '_dss', 'localhost:8080'],
                    checkNavURL = str => {
                        if (url.includes(str)) this.serverPath = url.split(str)[0] + str + '/'
                    }

                locations.forEach(item => checkNavURL(item))
            },
            getTabContent() {
                let apiServer = (this.serverPath.includes('localhost') || this.serverPath.includes('jay98')) ? '//jay98/dss/' : this.serverPath

                //console.log(this.serverPath)
                // eslint-disable-next-line
                console.log(apiServer +'api/content/'+ this.id +'/elements/Title,TabComponent')

                axios.get(`${ apiServer }api/content/${ this.id }/elements/Title,TabComponent`)
                .then((res) => { 
                    const tabTree = res.data.elements

                    //get the tab title
                    let getTitle = (tabTitle) => {
                        for (const item of tabTitle) {
                            if (item.name == 'Title') {
                                this.tabTitle = item.value                      
                            } 
                        }
                    }

                    getTitle(tabTree)

                    //get the tab labels
                    let getLabel = (tabLabel) => {
                        for (const item of tabLabel) {
                            if (item.name == 'TabComponent') {
                                var filterTabLabel = item.children.filter(a => a.name === 'Title')
                                this.tabLabel.push(filterTabLabel[0].value)
                            } 
                        }
                    }

                    getLabel(tabTree)

                    //get the tab contents
                    let getContent = (tabContent) => {
                        for (const item of tabContent) {

                            if (item.name == 'TabComponent') {
                                var filterTabContent = item.children.filter(b => b.name === 'MainColumnXHTMLCopyComponent')
                                this.tabContent.push(filterTabContent[0].value)
                            } 
                        } 
                    }

                    getContent(tabTree)

                }).catch(() => {
                    // eslint-disable-next-line
                    console.log('No Tabs?')
                })
            }
        }
    }

</script>

<style lang="scss">
/* New tabls styling */
#mainColMain {
    .vueTabs {
        //float: left;

        h2.tabsList-title {
            padding: 30px 0;
            margin: 0;
            border-bottom: none;
            max-width: 100%;
        }

        .tabsContainer {
            [class^='stick-top'] {
                position: absolute;
            }
        
            .tabs {  
        
                background-color: #fff;
                font-weight: bold;
        
                &.tabsWrapper {
                    color: #888;
                    display: block;
        
                    /* Style the tabs */
                    .navbarContainer {
                        margin-bottom: 0;
                        white-space: nowrap;
                        margin-left: 0;
                        padding-bottom: 0;
                        border-bottom-width: 0;
        
                        &.scrollNav {
                            overflow-x: hidden;
                            font-size: 16px;  
                        }
        
                        ul {
                            list-style-type: none;
                            margin-left: 20px;
                        }
        
                        a {
                            &.tab {
                                float: left;
                                cursor: pointer;
                                padding: 15px 15px 11px 15px;
                                border-bottom: solid 4px transparent;
                                color: #666;
                                font-weight: bold;
                                max-width: 160px;
                                text-align: center;
                                display: table;
                                white-space: normal;
                                line-height: 18px;
                                height: 100%;
        
                                &:hover {
                                    background-color: #FFF8E1;
                                    color: #333;
                                }
        
                                &.active {
                                    color: #333;
                                    border-bottom: 4px solid #F7C305; 
                                    padding-bottom: -4px;
                                    text-decoration: none;
                                }
        
                                &:focus {
                                    outline: none;
                                }
                            }
        
                            p {
                                margin-bottom: 0;
                                display: table-cell;
                                vertical-align: middle;
                            }
                        }
        
                        &.stick {
                            margin-top: 0;
                            position: fixed;
                            top: 60px;
                            z-index: 1000;
                            min-height: 0;  
                            border-bottom: solid 1px #ccc;
                            scrollbar-width: none; 
                        } 
        
                        div {
                            float: left;
                        }
        
                        .navBarWrap {
                            display: grid !important;
                            grid-auto-flow: column;
                            margin-bottom: 0;
                            display: block;
                            overflow: hidden;
                            position: relative;
                            background: #fff;
                        } 
                    }
        
                    .navbar {
                        background-color: #fff;
                    }
        
                    .gradient-right, .gradient-left {
                        width: 100px;
                        height: 100%;
                        margin-bottom: 0;
                        position: absolute;
                        z-index: 0;
                        left: 0; 
                        bottom: 0; 
                        cursor: pointer;
        
                        &:after {
                            z-index: 5;
                            width: 100%;
                            height: 100%;
                            position: absolute;
                            content: "";
                            z-index: 4;
                            top: 0;
                            bottom: 15px;
                            pointer-events: none;
                        }
        
                        svg {
                            width: 7px;
                            height: 100%;
                            position: absolute;
                            //top: 46%;
                            z-index: 10;
                            color: #003da5;
                        }
                    }
        
                    .gradient-left {
                        &:after {
                            right: 0;
                            background-image: linear-gradient(to left, rgba(255,255,255,0), #fff 85%);
                        }
        
                        .fa-chevron-left {
                            left: 0;
                        }
                    }
        
                    .gradient-right {
                        &:after {
                            right: 0;
                            background-image: linear-gradient(to right, rgba(255,255,255,0), #fff 85%);
                        }
        
                        .fa-chevron-right {
                            right: 1px;
                        }
                    }
        
                    .content {
                        color: #000;
                        font-weight: normal; 
        
                        /* Style the tab content */
                        .tabContent {
                            padding: 30px;
                            border: 1px solid #DADAD6;
                            color: #000;
                            font-weight: normal;
                            min-height: 300px;
        
                            h2, h3, h4, h5, h6, div, span, ul, li, p, a {
                                max-width: 100%;
                            }
        
                            h2, h3, h4, h5, h6 {
                                margin-top: 0;
                            }
                        } 
                    }
                }
            }
        
            @media (min-width: 576px) and  (max-width: 767px) {
                .tabs.tabsWrapper { 
        
                    .scrollNav {
                        padding-left: 0;
                    }
        
                    .navbarContainer {
        
                        .fa-chevron-right, .fa-chevron-left {
                            //top: 54%;
                        }
        
                    }
        
                }
            }
        
            @media (max-width: 575px) {
                .tabs.tabsWrapper {  
                    .tabcontent {
                        padding: 15px;
                    }
        
                    .navbarContainer {
                        &.stick {
                            width: 100%;
                        }
        
                        &.scrollNav {
                            padding-left: 0;
                            overflow-x: auto;
                        }
        
                        .fa-chevron-right, .fa-chevron-left {
                            //top: 54%;
                        }
                    } 
                }
            }
        }
    }
}
</style>